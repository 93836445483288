/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useRef } from "react";
import { Select, Typography, Radio } from "antd";
import { initializeJSTSession } from "../../api/jst";
import { Dispatch, LastSymptomsSearch } from "../../api/JSTProvider";
import Immutable, { List, Map, is } from "immutable";

const { Option } = Select;

const styles = {
  symptomSelect: css`
    display: flex;
    align-items: center;
  `,
  select: css`
    max-width: 500px;
    min-width: 15rem;
    width: 100%;
    div.ant-select-selector {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-right: 3rem;
    }
  `,
  button: css`
    margin-left: 0.5rem;
  `,
  label: css`
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  `,
  genderAgeGroup: css`
    display: flex;
    & > div {
      margin-right: 2rem;
    }
  `,
  genderSelect: css`
    min-width: 7rem;
  `,
};

const rounded = (v: number) => Math.round(v * 100) / 100;
const mean = (v1: number, v2: number) => (v1 + v2) / 2;
const roundedMean = (v1: number, v2: number) => rounded(mean(v1, v2));

export const ageGroups: Record<string, number> = {
  "0-3kk": roundedMean(0, 3 / 12),
  "4-12kk": roundedMean(4 / 12, 1),
  "1-3v": roundedMean(1, 3),
  "4-9v": roundedMean(4, 9),
  "10-15v": roundedMean(10, 15),
  "16-18v": roundedMean(16, 18),
  "19-29v": roundedMean(19, 29),
  "30-39v": roundedMean(30, 39),
  "40-55v": roundedMean(40, 55),
  "56-130v": roundedMean(56, 130),
};

type SymptomSelectProps = {
  symptoms: List<string>;
  age?: number;
  gender?: "M" | "F" | "";
  lastSymptomsSearch: Immutable.Record<LastSymptomsSearch>;
  keywords: List<string>;
  loading: boolean;
  dispatch: Dispatch;
};

function SymptomSelect({
  symptoms,
  age,
  gender,
  lastSymptomsSearch,
  keywords,
  loading,
  dispatch,
}: SymptomSelectProps) {
  const symptomSelectRef = useRef<any>(null);

  return (
    <div>
      <div css={styles.genderAgeGroup}>
        <div>
          <Typography.Text type="secondary" css={styles.label}>
            Sukupuoli
          </Typography.Text>
          <Select
            size="small"
            value={gender}
            css={styles.genderSelect}
            onChange={(value) => {
              dispatch({ type: "set-gender", payload: value });
              initializeJSTSession({
                symptoms,
                age,
                gender: Immutable.fromJS(value),
                dispatch,
              });
            }}
          >
            <Option value="">Ei valittu</Option>
            <Option value="N">Nainen</Option>
            <Option value="M">Mies</Option>
          </Select>
        </div>
        <div>
          <Typography.Text type="secondary" css={styles.label}>
            Ikäryhmä
          </Typography.Text>
          <Radio.Group
            size="small"
            value={age}
            onChange={(event) => {
              dispatch({ type: "set-age", payload: event.target.value });
              initializeJSTSession({
                symptoms,
                age: Immutable.fromJS(event.target.value),
                gender,
                dispatch,
              });
            }}
          >
            {Object.keys(ageGroups).map((group) => (
              <Radio.Button key={group} value={ageGroups[group]}>
                {group}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div>
        <Typography.Text type="secondary" css={styles.label}>
          Oireet
        </Typography.Text>
        <div css={styles.symptomSelect}>
          <Select
            mode="multiple"
            css={styles.select}
            ref={symptomSelectRef}
            placeholder="Kirjoita tärkeimmät oireet"
            value={symptoms.toJS()}
            onChange={(value) => {
              symptomSelectRef.current && symptomSelectRef.current.blur();
              dispatch({ type: "set-symptoms", payload: value });
              initializeJSTSession({
                symptoms: Immutable.fromJS(value),
                age,
                gender,
                dispatch,
              });
            }}
          >
            {keywords.map((kw: string) => (
              <Option key={kw} value={kw}>
                {kw}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}

// SymptomSelect would get re-rendered due to useContext in spite of React.memo.
// As such, the current fix is to pass in *only* the necessary parts of the JSTContext to SymptomSelect, so that reaching for state
// inside SymptomSelect doesn't cause re-renders.
// This is important, as one primary cause of sluggishness in the Survey component form fields was due to SymptomSelect re-rendering
// every time anything changed.
export default React.memo(
  SymptomSelect,
  (
    { dispatch: prevDispatch, ...prevRest },
    { dispatch: nextDispatch, ...nextRest }
  ) => {
    return is(
      Map(prevRest as Record<string, any>),
      Map(nextRest as Record<string, any>)
    );
  }
);
