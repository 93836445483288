/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect } from "react";
import { Row, Col, Typography, Button, Radio } from "antd";
import theme from "../theme";
import { UserData } from "../api/user";
import { useLogout } from "../api/Authorization/Authentication";
import { useUserState } from "../api/UserProvider";
import { useLocation, Link, useHistory } from "react-router-dom";
import paths from "../paths";
import { kuuraLogo } from "../common/assets";
import SearchBar from "./SearchBar";
import { getSearchParams, resultsPageLink, LINK_TYPES } from "../api/search";
import { useSearchState, useSearchDispatch } from "../api/SearchProvider";

const useStyles = (page: string) => ({
  root: css`
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: ${page === paths.results ? "1px solid #d9d9d9" : "none"};
  `,
  left: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
  right: css`
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  `,
  logout: css`
    color: ${theme.secondary};
  `,
  logo: css`
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    img {
      width: 2rem;
      height: auto;
    }
  `,
  search: css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  filtersGroup: css`
    margin-top: 1rem;
    margin-left: 1.8rem;
  `,
  filtersRadio: css`
    border: none !important;
    display: inline-flex;
    padding: 0 10px;
    height: 25px !important;
    &::before {
      display: none !important;
    }
    span:last-of-type {
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 14px;
      img {
        height: 16px;
        width: auto;
        margin-right: 0.25rem;
      }
    }
  `,
});

const prettifiedName = (userState: UserData) => {
  const roles: Record<string, string> = {
    DOCTOR: "Lääkäri",
    NURSE: "Hoitaja",
  };
  return `${roles[userState.usertype]} ${userState.firstname}`;
};

function LocalLogout() {
  const { loading, request } = useLogout();
  const { pathname } = useLocation();
  const styles = useStyles(pathname);

  return (
    <Button
      type="link"
      css={styles.logout}
      disabled={loading}
      onClick={() => {
        request();
        localStorage.removeItem("oirekysely");
      }}
    >
      Kirjaudu ulos
    </Button>
  );
}

function Auth0Logout() {
  return (
    <form action="/logout" method="POST">
      <input
        type="hidden"
        name="redirectTo"
        value={window.location.href || "/"}
      />

      {process.env.REACT_APP_AUTH0_LOGIN === "true" ? (
        <Auth0Logout />
      ) : (
        <LocalLogout />
      )}
    </form>
  );
}

function Filters() {
  const { search, pathname } = useLocation();
  const styles = useStyles(pathname);
  const { queryParams } = useSearchState();
  const dispatch = useSearchDispatch();
  const { replace } = useHistory();

  useEffect(() => {
    const urlQueryParams = getSearchParams(search);
    if (urlQueryParams !== queryParams) {
      replace(resultsPageLink(queryParams));
    }
  }, [replace, queryParams, search]);

  return (
    <Radio.Group
      css={styles.filtersGroup}
      defaultValue={queryParams.type || ""}
      size="large"
      onChange={(event) =>
        dispatch({
          type: "update-search-query",
          payload: { ...queryParams, type: event.target.value },
        })
      }
    >
      {LINK_TYPES.map((item, idx) => (
        <Radio.Button key={idx} value={item.value} css={styles.filtersRadio}>
          {item.icon && <img src={item.icon} alt={item.title} />}
          <span>{item.title}</span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
}

export default function Header() {
  const userState = useUserState();
  const { pathname } = useLocation();
  const styles = useStyles(pathname);

  const showSearch = pathname !== paths.login;
  const isSearchPage = [paths.results, paths.search].includes(pathname);

  return (
    userState && (
      <div css={styles.root}>
        <Row>
          {/* Search */}
          <Col span={16} css={styles.left}>
            {showSearch && (
              <React.Fragment>
                <div css={styles.search}>
                  <div css={styles.logo}>
                    <Link to={paths.root}>
                      <img src={kuuraLogo} alt="Kuura logo" />
                    </Link>
                  </div>
                  <SearchBar />
                </div>
                {isSearchPage && <Filters />}
              </React.Fragment>
            )}
          </Col>

          {/* Login */}
          <Col span={8} css={styles.right}>
            <Typography>{prettifiedName(userState)}</Typography>

            {process.env.REACT_APP_AUTH0_LOGIN === "true" ? (
              <Auth0Logout />
            ) : (
              <LocalLogout />
            )}
          </Col>
        </Row>
      </div>
    )
  );
}
