import React from "react";
import { Route } from "react-router-dom";

type ContextRouteProps = {
  provider: (props: any) => JSX.Element;
  component: (props: any) => JSX.Element;
};

export default function ContextRoute({
  provider,
  component,
  ...rest
}: ContextRouteProps & Record<string, any>) {
  const Provider = provider;
  const Component = component;

  return (
    <Route {...rest}>
      <Provider>
        <Component />
      </Provider>
    </Route>
  );
}
