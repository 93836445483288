import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "../api/Authorization/AuthContext";
import paths from "../paths";

export default (props: any) => {
  const authContext = useContext(AuthContext);

  if (authContext.isAuthenticated) {
    return <Route {...props} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: paths.login,
          state: { referrer: window.location.href },
        }}
      />
    );
  }
};
