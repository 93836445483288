// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useState } from "react";
import { Form, Input, Button, Typography, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { useLogin } from "../../api/Authorization/Authentication";
import { kuuraLogo } from "../../common/assets";
import theme from "../../theme";

const { Title } = Typography;

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    max-width: 600px;
    padding: 1rem;
    margin: auto;
    div.ant-form-item {
      width: 300px;
    }
  `,
  heading: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    img {
      height: 4rem;
      width: auto;
    }
    h1 {
      margin: 0;
      margin-left: 0.5rem;
    }
  `,
  input: css`
    span.ant-input-prefix {
      margin-right: 12px;
    }
  `,
  button: css`
    background: ${theme.kuurablue};
    border-color: ${theme.kuurablue};
    width: 100% !important;
  `,
  alert: css`
    margin-bottom: 2rem;
  `,
};

export default function LoginPage(props: any) {
  if (process.env.REACT_APP_AUTH0_LOGIN === "true")
    return (
      <Auth0Login redirectTo={((props.location || {}).state || {}).referrer} />
    );
  else return <LocalLogin />;
}

function Auth0Login(props: any) {
  return (
    <div css={styles.root}>
      <form action="/login" method="POST">
        <input
          type="hidden"
          name="redirectTo"
          value={props.redirectTo || window.location.href || "/"}
        />
        <div>
          <img src={kuuraLogo} alt="Kuura logo" />
          <div>
            <Button type="primary" htmlType="submit" size="large">
              Kirjaudu
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

function LocalLogin() {
  const { request, loading, error } = useLogin(); // TODO: read in error -> show alert if errored
  const [values, setValues] = useState({ username: "", password: "" });

  const onFinish = () => {
    if (values.username !== "" && values.password !== "") {
      // Login request
      request(values);
      localStorage.removeItem("oirekysely");
    }
  };

  return (
    <div css={styles.root}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <div css={styles.heading}>
          <img src={kuuraLogo} alt="Kuura logo" />
          <Title>Triaged</Title>
        </div>

        {error && (
          <Alert
            css={styles.alert}
            message="Kirjautuminen epäonnistui"
            type="error"
          />
        )}

        <Form.Item
          name="username"
          rules={[{ required: true, message: "Syötä käyttäjänimesi" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            css={styles.input}
            disabled={loading}
            size="large"
            placeholder="Käyttäjänimi"
            value={values.username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, username: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Syötä salasanasi" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            css={styles.input}
            disabled={loading}
            size="large"
            type="password"
            placeholder="Salasana"
            value={values.password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setValues({ ...values, password: e.target.value })
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            css={styles.button}
            type="primary"
            size="large"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            {!loading && "Kirjaudu sisään"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
