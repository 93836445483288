const config = {
  apiPrefix: "/api",
};

export class RequestError {
  error: Error;

  constructor(error: Error) {
    this.error = error;
  }
}

export class ConnectionError extends RequestError {
  getData(): null {
    return null;
  }
}

export class HTTPError<T> extends RequestError {
  public status?: number;
  public responseData?: T;

  getData() {
    return {
      status: this.status,
      responseData: this.responseData,
    };
  }
}

let errorListener: ErrorListener | null = null;

type ErrorListener = (error: RequestError) => void;

export function setErrorListener(listener: ErrorListener | null) {
  errorListener = listener;
}

export function sendErrorToListener(error: RequestError) {
  if (errorListener) {
    errorListener(error);
  }
}

export const defaultSettings: any = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  credentials: "include",
};

export function request<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  let transformedInput: any;
  if (typeof input === "string") {
    transformedInput = config.apiPrefix + input;
  } else {
    transformedInput = input;
  }

  return fetch(transformedInput, { ...defaultSettings, ...init })
    .catch((error: Error) => {
      throw new ConnectionError(error);
    })
    .then((response) => {
      return response
        .text()
        .then((text) => {
          try {
            const jsonData = JSON.parse(text);
            return jsonData as T;
          } catch (e) {
            return (null as unknown) as T;
          }
        })
        .then((data) => {
          if (response.ok) {
            return data;
          } else {
            const httpError = new HTTPError<T>(new Error());
            httpError.status = response.status;
            httpError.responseData = data;
            throw httpError;
          }
        });
    })
    .catch((error: RequestError) => {
      sendErrorToListener(error);
      throw error;
    });
}

export function requestFile(
  input: RequestInfo,
  init?: RequestInit
): Promise<File> {
  let transformedInput: any;
  if (typeof input === "string") {
    transformedInput = config.apiPrefix + input;
  } else {
    transformedInput = input;
  }

  return fetch(transformedInput, { ...defaultSettings, headers: {}, ...init })
    .catch((error: Error) => {
      throw new ConnectionError(error);
    })
    .then((response) => {
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "unknown_file";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2)
          fileName = fileNameMatch[1];
      }

      return response.blob().then((data) => {
        if (response.ok) {
          return new File([data], fileName);
        } else {
          const httpError = new HTTPError<any>(new Error());
          httpError.status = response.status;
          throw httpError;
        }
      });
    })
    .catch((error: RequestError) => {
      sendErrorToListener(error);
      throw error;
    });
}
