import React, { useEffect, useState, useCallback } from "react";

import { AuthContextValue } from "./AuthContext";
import AuthContext from "./AuthContext";
import { isAuthenticated } from "./Authentication";
import {
  ConnectionError,
  HTTPError,
  RequestError,
  setErrorListener,
} from "../../utils/fetchUtils";
import { notification } from "antd";

export default (props: any) => {
  // todo: move error notification to separate component
  const [openNotification, setOpenNotification] = useState(false);

  // Get context for AuthContext.Provider
  // notification needs to be coerced, as the type information is incomplete
  const [
    notificationApi,
    notificationContextHolder,
  ] = (notification as any).useNotification();

  const [authContext, setAuthContext] = useState<AuthContextValue>({
    isAuthenticated: getIsAuthed(),
    checkAuthentication: checkAuthentication,
    setAuthentication: setAuthentication,
  });

  function setAuthentication(isAuthenticated: boolean) {
    setAuthContext((state) => ({
      ...state,
      isAuthenticated: isAuthenticated,
    }));
  }

  function checkAuthentication() {
    setAuthContext((state) => {
      const isAuthed = getIsAuthed();

      if (state.isAuthenticated !== isAuthed) {
        return {
          ...state,
          isAuthenticated: isAuthed,
        };
      } else {
        return state;
      }
    });
  }

  function getIsAuthed(): boolean {
    return isAuthenticated();
  }

  const memoCheckAuth = useCallback(checkAuthentication, []);

  useEffect(() => {
    setErrorListener((err: RequestError) => {
      if (err instanceof HTTPError && err.status === 401) {
        deleteAllCookies();
        memoCheckAuth();
      } else if (err instanceof HTTPError && err.status === 500) {
        setOpenNotification(true);
      } else if (err instanceof ConnectionError) {
        setOpenNotification(true);
      }
    });

    return () => {
      setErrorListener(null as any);
    };
  }, [memoCheckAuth]);

  // Open notification
  useEffect(() => {
    if (openNotification) {
      notificationApi.error({
        message: "Ongelmia autentikaatiossa",
        description:
          "Pahoittelut, jotain meni pieleen! Yritä ladata sivu uudelleen.",
        placement: "bottomLeft",
        duration: 5,
      });
      setOpenNotification(false);
    }
  }, [notificationApi, openNotification]);

  return (
    <AuthContext.Provider value={authContext}>
      {notificationContextHolder}
      {props.children}
    </AuthContext.Provider>
  );
};

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
