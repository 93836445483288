import React, { useContext, useReducer, useEffect, useState } from "react";
import {
  QuestionData,
  AnswerOption,
  endpoint as getQuestionEndpoint,
  getDirectSearchPayload,
  getNextQuestionPayload,
} from "./getQuestion";
import { defaultOptions } from "../common/fetch";
import Immutable from "immutable";

// --- types ---

interface IState {
  messages: Immutable.List<Immutable.Record<QuestionData>>;
  answers: Immutable.List<Immutable.Record<AnswerOption>>;
  transfer?: Immutable.Record<{
    redirect: string | null;
    answerId: string | null;
  }>;
  error?: string;
}

export type State = { data: Immutable.Record<IState> };

export type Action =
  | {
      type: "upsert-answer";
      payload: { index: number; answer: Immutable.Record<AnswerOption> };
    }
  | { type: "reset-answer"; payload: number } // payload = index of answer in answers list
  | { type: "insert-messages"; payload: QuestionData[] }
  | { type: "transfer-patient"; payload: { answerId: number } }
  | { type: "set-redirect"; payload: boolean }
  | { type: "set-error"; payload: string }
  | { type: "reset-all" };

export type Dispatch = (action: Action) => void;

type BotProviderProps = { children: React.ReactNode };

// --- context ---

const BotStateContext = React.createContext<State | undefined>(undefined);
const BotDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const initialState: State = {
  data: Immutable.Record<IState>({
    messages: Immutable.List(),
    answers: Immutable.List(),
  })(),
};

function botReducer({ data }: State, action: Action): State {
  // If an answer for the same question already exists,
  // then only change that part of answers.
  // Otherwise, append to the existing answers array.
  if (action.type === "upsert-answer") {
    const { index, answer } = action.payload;
    const prevAnswer = data.getIn(["answers", index]);

    return {
      data: data.update("answers", (answers) =>
        prevAnswer ? answers.push(answer) : answers.set(index, answer)
      ),
    };
  }

  // Removes all answers **up to and including** the index specified in payload.
  // Removes all messages **up to, but not including** the index specified in payload.
  if (action.type === "reset-answer") {
    return {
      data: data
        .update("answers", (answers) => answers.take(action.payload))
        .update("messages", (messages) => messages.take(action.payload + 1)),
    };
  }

  if (action.type === "insert-messages") {
    return {
      data: data.update("messages", (messages) =>
        messages.push(Immutable.fromJS(action.payload))
      ),
    };
  }

  if (action.type === "transfer-patient") {
    return {
      data: data.setIn(
        ["transfer", "answerId"],
        Immutable.fromJS(action.payload)
      ),
    };
  }

  if (action.type === "set-redirect") {
    return {
      data: data.setIn(
        ["transfer", "redirect"],
        Immutable.fromJS(action.payload)
      ),
    };
  }

  if (action.type === "set-error") {
    return {
      data: data.set("error", action.payload),
    };
  }

  if (action.type === "reset-all") {
    return initialState;
  }

  // no-op
  return { data };
}

export function useBotState() {
  const context = useContext(BotStateContext);
  if (context === undefined) {
    throw new Error("useBotState must be used inside BotContext");
  }
  return context;
}

export function useBotDispatch() {
  const context = useContext(BotDispatchContext);
  if (context === undefined) {
    throw new Error("useBotDispatch must be used inside BotContext");
  }
  return context;
}

export function BotProvider({ children }: BotProviderProps) {
  const [state, dispatch] = useReducer(botReducer, initialState);

  return (
    <BotStateContext.Provider value={state}>
      <BotDispatchContext.Provider value={dispatch}>
        {children}
      </BotDispatchContext.Provider>
    </BotStateContext.Provider>
  );
}

// --- handlers ---

export function getNextMessage(
  dispatch: Dispatch,
  question: Immutable.Record<QuestionData>,
  answer: Immutable.Record<AnswerOption>
) {
  fetch(getQuestionEndpoint, {
    ...defaultOptions,
    method: "POST",
    body: JSON.stringify(getNextQuestionPayload(question, answer)),
  })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: "insert-messages", payload: data });
    })
    .catch((e) => {
      dispatch({
        type: "set-error",
        payload: "Pahoittelut, jotain meni pieleen.",
      });
    });
}

// --- hooks ---

// useBotSurvey will take care of the entire state management
// from first message to transfer / redirect
export function useBotSurvey() {
  const [query, setQuery] = useState("");
  const state = useBotState();
  const dispatch = useBotDispatch();

  // Initialization
  useEffect(() => {
    if (query && query !== "") {
      console.log("fetching with query:", query);
      dispatch({ type: "reset-all" }); // on new queries, need to reset everything
      fetch(getQuestionEndpoint, {
        ...defaultOptions,
        method: "POST",
        body: JSON.stringify(getDirectSearchPayload(query)),
      })
        .then((res) => res.json())
        .then((data) => {
          dispatch({ type: "insert-messages", payload: data });
        })
        .catch((e) => {
          dispatch({
            type: "set-error",
            payload: "Pahoittelut, bottia ei saatu aloitettua.",
          });
        });
    }
  }, [dispatch, query]);

  //TODO: add useEffect that listens on all state changes -> set loading to true (set to false on every insertion/update dispatch)

  return {
    updateQuery: (value: string) => {
      if (value !== query) setQuery(value);
    },
    data: state.data,
  };
}
