/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState, useEffect, useMemo } from "react";
import { Input, AutoComplete } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  useSearchAutocomplete,
  resultsPageLink,
  AutocompleteResults,
  getLinkTypeObject,
} from "../api/search";
import { useHistory } from "react-router-dom";
import { useSearchState, useSearchDispatch } from "../api/SearchProvider";

const styles = {
  autocomplete: css`
    width: 100%;
    max-width: 500px;
  `,
  option: css`
    display: flex;
    align-items: center;
    img {
      max-height: 16px;
      width: auto;
    }
  `,
  optionIcon: css`
    margin-right: 0.5rem;
  `,
};

// --- autocomplete options ---

type RenderableOption =
  | {
      index: number;
      type: "direct";
      item: { query: string; type: string };
    }
  | { index: number; type: "proposal"; item: string };

function getIcon(type?: string) {
  const linkData = type !== undefined && getLinkTypeObject(type);
  const icon =
    linkData && linkData?.icon ? (
      <img src={linkData.icon} alt={linkData.title} />
    ) : (
      <SearchOutlined />
    );
  return icon;
}

// These two functions (titleFromValue and typeFromValue) are hacks to work around a bug in Ant Design's Autocomplete (and Select) components,
// that clobbers the `key` prop for each option with its `value` prop.

function titleFromValue(value: string) {
  const splitting = value.split("/");
  return splitting[splitting.length - 1];
}

function typeFromValue(value: string) {
  const splitting = value.split("/");
  return splitting[splitting.length - 2];
}

function renderOption({ type, item, index }: RenderableOption) {
  let title, icon, value;

  // For search proposals
  if (typeof item === "string") {
    title = item;
    icon = getIcon();
    value = `${index}/${type}/${title}`;
  }
  // For direct links
  else {
    title = item.query;
    icon = getIcon(item.type);
    value = `${index}/${item.type}/${title}`;
  }

  return {
    value,
    label: (
      <div css={styles.option}>
        <span css={styles.optionIcon}>{icon}</span>
        {title}
      </div>
    ),
  };
}

function makeAutocompleteOptions({ proposals, direct }: AutocompleteResults) {
  return [
    {
      label: "Suoralinkit",
      options: direct.map((item, idx) =>
        renderOption({ type: "direct", item, index: idx })
      ),
    },
    {
      label: "Hakuehdotukset",
      options: proposals.map((item, idx) =>
        renderOption({ type: "proposal", item, index: idx })
      ),
    },
  ];
}

function debounce(f: (...args: any) => void, wait?: number) {
  let timeout: number | undefined;

  return (...args: any[]) => {
    const deferred = () => {
      timeout = undefined;
      f(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(deferred, wait);
  };
}

// --- actual search bar component ---

export default function SearchBar() {
  const { input, queryParams } = useSearchState();
  const [waitingAutocomplete, setWaitingAutocomplete] = useState(false);
  const dispatch = useSearchDispatch();
  const { results, autocomplete } = useSearchAutocomplete(() =>
    setWaitingAutocomplete(false)
  );
  const options = results || {
    proposals: [],
    direct: [],
    history: [],
  };
  const history = useHistory();
  const [initialized, setInitialized] = useState(false);
  const deferredAutocomplete = useMemo(
    () => debounce((q) => autocomplete(titleFromValue(q)), 1000),
    [autocomplete]
  );

  // if input is already filled in on page load...
  useEffect(() => {
    if (!initialized) {
      if (input !== "") {
        autocomplete(titleFromValue(input));
      }
      setInitialized(true);
    }
  }, [autocomplete, initialized, input]);

  // Handle search bar "submit" events, updating the browser history stack if need be
  const search = (query: string, type?: string) => {
    dispatch({ type: "update-search-query", payload: { query } });
    const link =
      type && type !== "proposal"
        ? getLinkTypeObject(type)?.link?.({
            query,
            attr: query.toLowerCase(),
          })
        : resultsPageLink({
            query,
            type: queryParams.type,
          });

    if (link) {
      history.push(link);
    }
  };

  return (
    <AutoComplete
      css={styles.autocomplete}
      options={makeAutocompleteOptions(options)}
      backfill={false}
      value={input}
      onChange={(query: string) => {
        dispatch({
          type: "update-search-input",
          payload: titleFromValue(query),
        });
      }}
      onSearch={(query: string) => {
        setWaitingAutocomplete(true);
        deferredAutocomplete(query);
      }}
      onSelect={(query: string) =>
        search(titleFromValue(query), typeFromValue(query))
      }
    >
      <Input.Search
        size="large"
        placeholder={"Aloita kirjoittamalla hakusana tai kysymys tähän..."}
        onPressEnter={() => search(input)}
        onSearch={() => search(input)}
        loading={waitingAutocomplete}
      />
    </AutoComplete>
  );
}
