import React from "react";
import { Breadcrumb } from "antd";
import { resultsPageLink } from "../api/search";
import { useSearchState } from "../api/SearchProvider";

// Shows breadcrumbs for page with search results page (unless hideSearchResults is true)
export default function Breadcrumbs({
  items,
  hideSearchResults,
  ...restOfProps
}: any) {
  const { queryParams } = useSearchState();

  return (
    <Breadcrumb {...restOfProps}>
      {!hideSearchResults && (
        <Breadcrumb.Item>
          <a href={resultsPageLink(queryParams)}>Haku</a>
        </Breadcrumb.Item>
      )}
      {items.map((item: any, idx: number) => (
        <Breadcrumb.Item key={idx}>
          {item.href ? <a href={item.href}>{item.title}</a> : item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
