/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import Immutable from "immutable";
import { AnswerOption, QuestionData } from "../../api/getQuestion";
import { useBotState, useBotDispatch, Dispatch } from "../../api/BotProvider";
import { AnswerButton, isOptionVisible } from "./AnswerButton";
import { AnswerInput } from "./AnswerInput";

const styles = {
  root: css`
    display: flex;
    justify-content: flex-end;
    & > div {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
  `,
};

type Option = Immutable.Record<AnswerOption>;
type Message = Immutable.Record<QuestionData>;

type ContainerProps = {
  options: Immutable.List<Option>;
  messageIndex: number;
};

type OptionsProps = {
  options: Immutable.List<Option>;
  message: Message;
  dispatch: Dispatch;
  currentAnswer: undefined | Option;
  index: number;
};

function Options(props: OptionsProps) {
  const { options, dispatch, index, message, currentAnswer } = props;

  // if we need text inputs, handle case separately
  if (options.getIn([0, "props", "type"])) {
    return <AnswerInput {...props} />;
  }

  // otherwise, just render as buttons
  return (
    <React.Fragment>
      {options.map((option, idx) => (
        <AnswerButton
          key={idx}
          option={option}
          dispatch={dispatch}
          index={index}
          message={message}
          currentAnswer={currentAnswer}
        />
      ))}
    </React.Fragment>
  );
}

export default function AnswerOptionsContainer({
  options,
  messageIndex,
}: ContainerProps) {
  const { data } = useBotState();
  const dispatch = useBotDispatch();
  const currentAnswer = data.getIn(["answers", messageIndex]) as
    | Immutable.Record<AnswerOption>
    | undefined;

  return (
    <div className="bot-answer-options" css={styles.root}>
      <div>
        <Options
          options={options.filter(
            (option) =>
              isOptionVisible(option, currentAnswer) && option.get("id") !== -1
          )}
          message={data.getIn(["messages", messageIndex])}
          dispatch={dispatch}
          currentAnswer={currentAnswer}
          index={messageIndex}
        />
      </div>
    </div>
  );
}
