type FetchHandlerProps = {
  endpoint: string;
  fetchOptions: Record<string, any>;
  onSuccess: (data: any) => void;
  onError: (error: any) => void;
};

export const defaultOptions: Record<string, any> = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  credentials: "include",
};

export const handleFetch = ({
  endpoint,
  fetchOptions,
  onSuccess,
  onError,
}: FetchHandlerProps) =>
  fetch(endpoint, { ...defaultOptions, ...fetchOptions })
    .then((response) => response.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => onError(error));
