import { useJSTState, useJSTDispatch, Dispatch } from "./JSTProvider";
import { useEffect, useState } from "react";
import { defaultOptions } from "../common/fetch";
import Immutable from "immutable";

// --- types ---

export type Gender = "N" | "M" | null;

export type SurveyResponse = -1 | 0 | 1; // "Ei" | "En tiedä" | "Kyllä"

export type SessionStartRequest = {
  ika?: string;
  oireet: string; // comma-separated values ("päänsärky,huimaus,pahoinvointi")
  sukupuoli?: Gender;
  syntymaAika?: string;
};

interface IOirelahto {
  _id: string;
  sukupuoli: Gender;
  nimi: string;
  sijainti: string | null;
  ryhma: string[];
  id: string;
  kuvaus: string | null;
  ikaryhma:
    | Immutable.List<{ min: number; max: number }>
    | { min: number; max: number }[];
  score: number;
}

interface ImmutableOirelahto extends IOirelahto {
  ikaryhma: Immutable.List<{ min: number; max: number }>;
}

interface MutableOirelahto extends IOirelahto {
  ikaryhma: { min: number; max: number }[];
}

export interface ISessionStartResponse {
  ika: string;
  oireet: string;
  sukupuoli: Gender;
  syntymaAika: string;
  aikaleima: number;
  _id: string;
  kayttaja: any;
  oirelahto: ImmutableOirelahto | MutableOirelahto;
}

// Things to note about questions:
// - Each question should have "Kyllä", "Ei", and "En tiedä" as options
// - Each question should have a "Lisätiedot" field
// - The `eiKesto` property value determines if there should be a follow-up question for the symptom's duration

export type JSTQuestionID = string;

export interface IJSTQuestionResponse {
  vastaus: SurveyResponse;
  lisatieto: null | string;
  kesto?: string;
}

// Field added by Survey -> check if new questions raised
export interface IJSTQuestionResponse {
  vastaus: SurveyResponse;
  lisatieto: null | string;
  kesto?: string;
}

export interface IJSTQuestion {
  _id: JSTQuestionID;
  kysymys: string;
  painoKylla: number; // ??
  eiKesto: null | "K"; // branches: "K" -> no follow-up, null -> follow-up
  hakusanat: string;
  oireLahtoId: string;
  jarjestys: number;
  vakava: null | "K";
  kysymysId: number;
  painoEi: number; // ??
  tarkenne: null; // ??
  taso: number;
  selite?: string;
  vastaus: {
    vastaus: number;
  };
}

export interface ISessionQuestionRequest {
  // From triaged.kuurahealth.com/apidoc:
  // "The id can be found in `oirelahto.id` from the session object returned from `/start`"
  // (i.e. in SessionStartResponse)
  id: number;
}

interface IDisorderResult {
  id: number;
  nimi: string;
  pisteet: number;
  min: number;
  max: number;
  delta: number;
  kokonaispisteet: number;
  triage: number;
  tarkeys: number;
  mahdollinen: boolean;
  todennakoinen: boolean;
  lisatieto: string;
  artikkelit:
    | Immutable.List<{
        jarjestys: number;
        tunnus: string;
        otsikko: string;
      }>
    | {
        jarjestys: number;
        tunnus: string;
        otsikko: string;
      }[];
  koodit:
    | Immutable.List<{
        koodityyppi: string;
        koodi: string;
        otsikko: string;
      }>
    | {
        koodityyppi: string;
        koodi: string;
        otsikko: string;
      }[];
  suunnitelma: any; // ?? - something other than null?
}

export interface ISurveyResults {
  osumia: number;
  todennakoiset: Immutable.List<IDisorderResult> | IDisorderResult[];
  mahdolliset: Immutable.List<IDisorderResult> | IDisorderResult[];
}
export interface ISurveyTriage {
  _id: string;
  chatbotId: number;
  triage: string;
  suositus: string;
}

export interface ISurveyPlan {
  _id: string;
  chatbotId: number;
  suunnitelma: string;
  vakiosuunnitelmaId: number;
}

type Symptoms = Immutable.List<string>;
type Session = Immutable.Record<ISessionStartResponse>;
type Questions = Immutable.List<Immutable.Record<IJSTQuestion>>;
type Responses = Immutable.Map<
  JSTQuestionID,
  Immutable.Record<IJSTQuestionResponse>
>;

export type Resource = {
  id: string;
  kuvaus: string;
  url: string;
};

// --- endpoints ---

const endpoints = {
  getKeywords: "/api/v1/jst/hakusanat",
  startSession: "/api/v1/jst/start",
  getSymptomQuestions: (id: number) => `/api/v1/jst/oirelahto/${id}`,
  getNewQuestions: "/api/v1/jst/question",
  getResults: "/api/v1/jst/result",
  getExternalResources: "/api/v1/jst/aineistot",
};

// --- handlers ---

type InitSession = {
  dispatch: Dispatch;
  symptoms: Symptoms;
  age?: number;
  gender?: "M" | "F" | "";
};

export function initializeJSTSession({
  dispatch,
  symptoms,
  age,
  gender,
}: InitSession) {
  // POST body
  const body = {
    oireet: symptoms.join(","),
    ...(age ? { ika: age } : {}),
    ...(gender ? { sukupuoli: gender } : {}),
  };

  // Signal that we are fetching data (show loading skeleton)
  dispatch({ type: "begin-fetch" });
  // Then do the fetching
  fetch(endpoints.startSession, {
    ...defaultOptions,
    ...{
      method: "POST",
      cache: "no-cache",
      body: JSON.stringify(body),
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Pahoittelut, kyselyä ei pystytty hakemaan.");
      }
    })
    .then((data) => {
      dispatch({ type: "start-session", payload: data });
      if (data?.oirelahto?.id === undefined) {
        dispatch({
          type: "set-info",
          payload: "Pahoittelut, tällä oirekombinaatiolla ei löytynyt kyselyä.",
        });
        dispatch({ type: "stop-loading" });
      } else {
        // ... then get questions for the user's described symptoms using the new session id
        fetch(endpoints.getSymptomQuestions(data.oirelahto.id), defaultOptions)
          .then((res) => res.json())
          .then((questions) => {
            dispatch({
              type: "upsert-initial-questions",
              payload: questions,
            });
          });
      }
    })
    .catch((error) => {
      dispatch({
        type: "set-error",
        payload: error.message,
      });
      dispatch({ type: "stop-loading" });
    });
}

// Merge session with questions and responses
function mergeSession({
  session,
  questions,
  responses,
}: {
  session: Immutable.Record<any>; // due to issues with set()
  questions: Questions;
  responses: Responses;
}) {
  return session.set(
    "kysymykset",
    questions.map((question: Immutable.Record<any>) =>
      question.set(
        "vastaus",
        responses.get(question.get("_id")) || Immutable.Map()
      )
    )
  );
}

// Update JST session object
// Called when survey responses change
export function updateJSTSession({
  session,
  questions,
  responses,
  dispatch,
  fetchResultsOn404,
}: {
  session?: Session;
  questions: Questions;
  responses: Responses;
  dispatch: Dispatch;
  fetchResultsOn404: boolean;
}) {
  if (!session) return; // TODO: is this adequate?

  fetch(endpoints.getNewQuestions, {
    ...defaultOptions,
    ...{
      method: "POST",
      body: JSON.stringify(mergeSession({ session, questions, responses })),
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else if (res.status === 404) {
        if (fetchResultsOn404) {
          getResults({ session, questions, responses, dispatch });
        }
      } else {
        throw new Error("Pahoittelut, kyselyä ei pystytty päivittämään.");
      }
    })
    .then((data) => {
      if (typeof data === "object") {
        dispatch({ type: "upsert-new-questions", payload: data });
      }
    })
    .catch((error) => {
      dispatch({
        type: "set-error",
        payload: error.message,
      });
      dispatch({ type: "stop-loading" });
    });
}

export function getResults({
  session,
  questions,
  responses,
  dispatch,
  noRedirect,
}: {
  session?: Session;
  questions: Questions;
  responses: Responses;
  dispatch: Dispatch;
  noRedirect?: boolean;
}) {
  if (!session) return; // TODO: is this adequate?

  dispatch({ type: "updating-results" });

  fetch(endpoints.getResults, {
    ...defaultOptions,
    ...{
      method: "POST",
      body: JSON.stringify(mergeSession({ session, questions, responses })),
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw new Error("Pahoittelut, tuloksia ei saatu haettua.");
      }
    })
    .then((data) => {
      // data may also contain (modified) questions (e.g. with `selite` field)
      dispatch({ type: "update-questions", payload: data.kysymykset });
      dispatch({ type: "set-triage", payload: data.triage });
      dispatch({ type: "set-plan", payload: data.suunnitelma });
      dispatch({ type: "set-results", payload: data.tulos });
      dispatch({ type: "stop-loading" });
      // for symptom survey, don't redirect when fetching new results
      if (!noRedirect) {
        dispatch({ type: "finish-survey" });
      }
    })
    .catch((error) => {
      dispatch({ type: "set-error", payload: error.message });
    });
}

export function getExternalResources(dispatch: Dispatch) {
  fetch(endpoints.getExternalResources, { ...defaultOptions, method: "GET" })
    .then((res) => res.json())
    .then((data) => {
      dispatch({ type: "set-resources", payload: data });
    })
    .catch((error) => {
      dispatch({ type: "set-error", payload: error.message });
    });
}

// --- hooks ---

// Handle fetching allowable keywords for a JST session
export function useJSTKeywords() {
  const state = useJSTState();
  const dispatch = useJSTDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unmounted = false;

    if (loading) {
      fetch(endpoints.getKeywords, { method: "GET" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error(
              "Pahoittelut, hakukentän avainsanoja ei saatu haettua."
            );
          }
        })
        .then((kws) => {
          dispatch({ type: "set-keywords", payload: Object.values(kws) });
          if (!unmounted) {
            setLoading(false);
          }
        })
        .catch((error) =>
          dispatch({ type: "set-error", payload: error.message })
        );
    }

    return () => {
      unmounted = true;
    };
  }, [dispatch, loading]);

  return { loading, keywords: state.get("keywords") };
}
