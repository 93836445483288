/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import {
  useSearchFull,
  SearchResult,
  getSearchParams,
  getLinkTypeObject,
} from "../../api/search";
import paths from "../../paths";
import { Typography, Empty } from "antd";
import { NavLink } from "react-router-dom";
import theme from "../../theme";
import { useSearchState } from "../../api/SearchProvider";
import LoadingSkeleton from "../../components/LoadingSkeleton";

const { Text } = Typography;

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  summary: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  results: css`
    .ant-skeleton {
      max-width: 500px;
    }
  `,
  resultItem: css`
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
  `,
  resultItemLeft: css`
    margin-right: 1rem;
    margin-top: 6px;
    img {
      width: 25px;
      height: auto;
    }
  `,
  link: css`
    color: ${theme.kuurablue};
    font-size: 1rem;
  `,
  resultDescription: css`
    max-width: 500px;
    word-break: break-word;
  `,
  mainLink: css`
    display: block;
  `,
  proposals: css`
    display: flex;
    flex-direction: column;
    a {
      font-size: 14px;
    }
  `,
  empty: css`
    margin: 2rem;
    width: max-content;
  `,
};

const uniformTitle = (titleList: string) =>
  titleList
    .split(",")
    .map((el) => el.trim().toLowerCase())
    .join(",");

function ResultItem({
  title,
  type,
  description,
  proposals,
  url,
}: SearchResult) {
  const { queryParams } = useSearchState();
  const linkData = getLinkTypeObject(type);

  const createLink = (title: string) => {
    const linkUrl = makeTitle(title);
    // Handle external links
    if (linkUrl.startsWith("http://") || linkUrl.startsWith("https://"))
      return (
        <a
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={styles.link}
        >
          {title}
        </a>
      );

    return (
      <NavLink to={linkUrl} css={styles.link}>
        {title}
      </NavLink>
    );
  };

  const makeTitle = (title: string) =>
    linkData?.link
      ? linkData.link({
          query: queryParams.query,
          attr: uniformTitle(title),
          url: url,
        })
      : "#";

  return (
    <div css={styles.resultItem}>
      <div css={styles.resultItemLeft}>
        {linkData?.icon && <img src={linkData.icon} alt={linkData.title} />}
      </div>
      <div>
        <div css={styles.mainLink}>{createLink(title)}</div>
        {proposals && (
          <div css={styles.proposals}>
            {proposals?.map((proposal) => (
              <NavLink
                key={proposal}
                to={makeTitle(proposal)}
                css={styles.link}
              >
                {proposal}
              </NavLink>
            ))}
          </div>
        )}
        <Typography css={styles.resultDescription}>{description}</Typography>
      </div>
    </div>
  );
}

export default function SearchResultsPage(props: any) {
  const location = useLocation();
  const { results, queryParams, loading } = useSearchState();
  const [urlQueryParams, setURLQueryParams] = useState(
    getSearchParams(location.search)
  );
  const { search } = useSearchFull();

  // Do search when search query params have updated
  useEffect(() => {
    if (queryParams.query && urlQueryParams !== queryParams) {
      search(queryParams);
      setURLQueryParams(queryParams);
    }
  }, [queryParams, search, urlQueryParams]);

  // Redirect to root if invalid search params
  if (!queryParams) {
    return <Redirect to={paths.search} />;
  }

  return (
    <div css={styles.root}>
      <div css={styles.results}>
        {loading ? (
          <LoadingSkeleton />
        ) : (
          <div>
            {queryParams.query && (
              <Text type="secondary" css={styles.summary}>
                Tulokset haulle "{queryParams.query}"
              </Text>
            )}
            {results && results.length > 0 ? (
              results.map((result, idx) => <ResultItem key={idx} {...result} />)
            ) : (
              <Empty
                css={styles.empty}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Ei hakutuloksia"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
