// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import { notification, Typography, Row, Col, Divider } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { initializeJSTSession, useJSTKeywords } from "../../api/jst";
import { useJSTDispatch, useJSTState } from "../../api/JSTProvider";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import SymptomSelect from "./SymptomSelect";
import Survey from "./Survey";
import { List } from "immutable";
import paths from "../../paths";
import Breadcrumbs from "../../components/Breadcrumbs";
import { useLocalStorage } from "../../utils/localStorage";
import useInterval from "../../utils/useInterval";
import SymptomResults from "../SymptomResultsPage";

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  helpText: css`
    margin-bottom: 1rem;
    white-space: pre-line;
  `,
  loadingSkeleton: css`
    max-width: 600px;
  `,
  breadcrumbs: css`
    margin-bottom: 1rem;
  `,
};

export default function SymptomPage() {
  const state = useJSTState();
  const location = useLocation();
  const error = state.get("error");
  const loading = state.get("loading");
  const description = state.get("description");
  const lastSymptomsSearch = state.get("lastSymptomsSearch");
  const symptoms = state.get("symptoms");
  const age = state.get("age");
  const gender = state.get("gender");
  const { keywords, loading: loadingSelect } = useJSTKeywords();
  const dispatch = useJSTDispatch();
  const [stateLocalStorage, setStateLocalStorage] = useLocalStorage(
    "oirekysely"
  );
  const { search } = useLocation();
  const history = useHistory();
  const parsedQuery = queryString.parse(search);
  const queried: Record<string, any> = search
    ? {
        symptoms: (parsedQuery.symptoms as string).split(","),
        age: parseFloat(parsedQuery.age as string) || 47.5,
        gender: parsedQuery.gender as string,
      }
    : { symptoms: [] };
  const [initializeBy, setInitializeBy] = useState(
    history.action === "POP" &&
      stateLocalStorage.symptoms &&
      stateLocalStorage.symptoms.length > 0
      ? "local-storage"
      : queried.symptoms && queried.symptoms.length > 0
      ? "query-string"
      : "none"
  );

  // Handle initialization (if possible)
  useEffect(() => {
    if (initializeBy === "query-string") {
      dispatch({ type: "set-gender", payload: queried.gender });
      dispatch({ type: "set-age", payload: queried.age });
      dispatch({ type: "set-symptoms", payload: queried.symptoms });
      initializeJSTSession({
        symptoms: List(queried.symptoms),
        gender:
          queried.gender === "" ||
          queried.gender === "M" ||
          queried.gender === "F"
            ? queried.gender
            : undefined,
        age: queried.age,
        dispatch,
      });
      setInitializeBy("none");
    }
    if (initializeBy === "local-storage") {
      dispatch({ type: "overwrite-data", payload: stateLocalStorage });
      dispatch({ type: "reset-description" });
      setInitializeBy("none");
    }
  }, [
    dispatch,
    initializeBy,
    queried.age,
    queried.gender,
    queried.symptoms,
    stateLocalStorage,
  ]);

  // Handle error notifications
  useEffect(() => {
    if (error) {
      notification.error({
        message: "Yhteysongelmia",
        description: error,
        placement: "bottomLeft",
      });
      dispatch({ type: "set-error", payload: undefined });
    }
  }, [dispatch, error]);

  // Handle redirect with state passing
  useEffect(() => {
    if (description === "finished-survey") {
      history.push({
        pathname: paths.symptomResults,
        search: location.search,
      });
    }
  }, [description, history, location.search, state]);

  // Persist state in localStorage every second
  useInterval(() => setStateLocalStorage(state.toJS()), 1000);

  return (
    <div css={styles.root}>
      <Breadcrumbs
        items={[{ title: "Oirekysely " }]}
        css={styles.breadcrumbs}
      />
      <Typography css={styles.helpText}>
        {/* As JS string to include newline */}
        {`Valitse potilaan ikäryhmä, sukupuoli (valinnainen) ja kirjoita potilaan oireet alla olevaan kenttään.
          Oirekysely päivittyy kun olet painanut "Päivitä kysymykset" nappia.`}
      </Typography>
      <SymptomSelect
        symptoms={symptoms}
        age={age}
        gender={gender}
        lastSymptomsSearch={lastSymptomsSearch}
        dispatch={dispatch}
        keywords={keywords}
        loading={loadingSelect}
      />
      {loading === "fetching-data" ? (
        <LoadingSkeleton css={styles.loadingSkeleton} hideHeading />
      ) : (
        <div>
          <Divider />
          <Row>
            <Col span={12}>
              <Survey />
            </Col>
            <Col span={12}>
              <SymptomResults short />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
