import { useState, useEffect } from "react";
import paths from "../paths";
import queryString from "query-string";
import { useSearchDispatch } from "./SearchProvider";
import { icons } from "../common/assets";
import { handleFetch } from "../common/fetch";

// --- types ---

export type AutocompleteResults = {
  history: {
    id: number;
    query: string;
    type: string;
  }[];
  direct: {
    query: string;
    type: string;
  }[];
  proposals: string[];
};

export type SearchResult = {
  title: string;
  type: string;
  description?: string;
  proposals?: string[];
  url?: string;
};

export type FullSearchQueryParams = {
  query: string;
  type?: string;
  page?: number;
};

// --- consts ---

export const LINK_TYPES = [
  { title: "Kaikki", icon: null, value: "" },
  {
    title: "Chatbot",
    icon: icons.chatbot,
    value: "chatbot",
    link: ({ query, attr, url }: Record<string, string | undefined>) =>
      `${paths.bot}?symptoms=${attr}&query=${query}`,
  },
  {
    title: "Oirekysely",
    icon: icons.oirekysely,
    value: "symptom",
    link: ({ query, attr, url }: Record<string, string | undefined>) =>
      `${paths.symptom}?query=${query}&symptoms=${attr}`,
  },
  {
    title: "Terveysportti",
    icon: icons.terveysportti,
    value: "terveysportti",
    link: ({ query, attr, url }: Record<string, string | undefined>) =>
      `${paths.terveysportti}${url}`,
  },
  { title: "Dokumentit", icon: icons.dokumentti, value: "document" },
  { title: "Yhteisötuki", icon: icons.yhteisotuki, value: "support" },
];

// --- endpoints ---

const endpoint = "/api/v1/search";

const autocompleteEndpoint = (query: string) =>
  `${endpoint}/autocomplete?query=${query}`;

// Query string components for endpoint
const paramStrings = ({ query, type, page }: FullSearchQueryParams) => {
  return {
    queryStr: `query=${query}`,
    typeStr: type ? `&type=${type}` : "",
    pageStr: `&page=${page || 1}`,
  };
};

export const fullSearchEndpoint = (queryParams: FullSearchQueryParams) => {
  const { queryStr, typeStr, pageStr } = paramStrings(queryParams);
  return `${endpoint}/full?${queryStr}${typeStr}${pageStr}`;
};

// --- utils ---

export const getLinkTypeObject = (type: string) => {
  for (const linkType of LINK_TYPES) {
    if (linkType.value === type) return linkType;
  }
  return null;
};

export const resultsPageLink = (queryParams: FullSearchQueryParams) => {
  const { queryStr, typeStr, pageStr } = paramStrings(queryParams);
  return `${paths.results}?${queryStr}${typeStr}${pageStr}`;
};

// Parse query parameters for search from URL
export function getSearchParams(search: any): FullSearchQueryParams {
  const parsed = queryString.parse(search);

  return {
    query: typeof parsed.query === "string" ? parsed.query : "",
    type: typeof parsed.type === "string" ? parsed.type : "",
    page: typeof parsed.page === "string" ? parseInt(parsed.page) : 1,
  };
}

// --- hooks ---

export function useSearchAutocomplete(onUpdate?: () => void) {
  const [query, setQuery] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [results, setResults] = useState<null | AutocompleteResults>(null);

  useEffect(() => {
    let unmounted = false;

    if (query) {
      handleFetch({
        endpoint: autocompleteEndpoint(query),
        fetchOptions: { method: "GET" },
        onSuccess: (data) => {
          setResults(data);
          if (onUpdate) onUpdate();
        },
        onError: (error) => {
          if (!unmounted) setError("Pahoittelut, haku ei onnistunut.");
        },
      });

      // reset query
      setQuery(null);
    }

    return () => {
      unmounted = true;
    };
  }, [onUpdate, query]);

  return { results, error, autocomplete: setQuery };
}

export function useSearchFull() {
  const [queryParams, setQueryParams] = useState<null | FullSearchQueryParams>(
    null
  );
  // const [error, setError] = useState<null | string>(null);
  // const [results, setResults] = useState<null | SearchResult[]>(null);
  const dispatch = useSearchDispatch();

  useEffect(() => {
    let unmounted = false;

    if (queryParams) {
      dispatch({ type: "fetching-search-results" });
      handleFetch({
        endpoint: fullSearchEndpoint(queryParams),
        fetchOptions: { method: "GET" },
        onSuccess: (data) => {
          if (!unmounted)
            dispatch({ type: "update-search-results", payload: data });
        },
        onError: (error) => {
          if (!unmounted)
            dispatch({
              type: "set-error",
              payload: {
                message: "Pahoittelut, haku ei onnistunut.",
                verbose: error,
              },
            });
        },
      });
    }

    return () => {
      unmounted = true;
    };
  }, [dispatch, queryParams]);

  return {
    // results,
    // error,
    search: (queryParams: FullSearchQueryParams) => setQueryParams(queryParams),
  };
}
