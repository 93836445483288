import React from "react";
import Immutable from "immutable";
import { Typography } from "antd";
import { Question } from "../../api/getQuestion";

type Props = { message: Immutable.Record<Question> };

export default function BotMessageText({ message }: Props) {
  return (
    <div className="bot-message-text">
      <Typography>
        <span dangerouslySetInnerHTML={{ __html: message.get("text") }} />
      </Typography>
    </div>
  );
}
