import React, { useContext, useReducer, useEffect, useState } from "react";
import { getSearchParams, FullSearchQueryParams, SearchResult } from "./search";
import { useLocation } from "react-router-dom";

type Error = { message: string; verbose: any };

type Action =
  | { type: "update-search-input"; payload: string }
  | {
      type: "update-search-query";
      payload: FullSearchQueryParams;
    }
  | { type: "update-search-results"; payload: SearchResult[] }
  | { type: "set-error"; payload: Error }
  | { type: "fetching-search-results" };
export type Dispatch = (action: Action) => void;
export type State = {
  input: string;
  queryParams: FullSearchQueryParams;
  results?: SearchResult[];
  error?: Error;
  loading: boolean;
};
type ProviderProps = { children: React.ReactNode };

const SearchStateContext = React.createContext<State | undefined>(undefined);
const SearchDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function reducer(state: State, action: Action): State {
  if (action.type === "update-search-input") {
    return { ...state, input: action.payload };
  }

  if (action.type === "update-search-query") {
    const { query, page, type } = action.payload;
    return {
      ...state,
      input: query,
      queryParams: {
        query,
        type: type !== undefined ? type : state.queryParams.type,
        page: page || 1,
      },
    };
  }

  if (action.type === "update-search-results") {
    return { ...state, results: action.payload, loading: false };
  }

  if (action.type === "set-error") {
    return { ...state, error: action.payload };
  }

  if (action.type === "fetching-search-results") {
    return { ...state, loading: true };
  }

  return state;
}

export function useSearchState() {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error("useSearchState must be used inside SearchContext");
  }
  return context;
}

export function useSearchDispatch() {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error("useSearchDispatch must be used inside SearchContext");
  }
  return context;
}

export function SearchProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(reducer, {
    input: "",
    queryParams: { query: "" },
    loading: false,
  });
  const { search } = useLocation();
  const queryParams = getSearchParams(search);
  const [initialized, setInitialized] = useState(false);

  // Populate input field if query in URL
  useEffect(() => {
    if (!initialized) {
      dispatch({ type: "update-search-query", payload: queryParams });
      setInitialized(true);
    }
  }, [initialized, queryParams]);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}
