/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { useBotSurvey } from "../../api/BotProvider";
import { useLocation } from "react-router-dom";
import BotMessage from "./BotMessage";
import AnswerOptionsContainer from "./AnswerOptionsContainer";
import Breadcrumbs from "../../components/Breadcrumbs";
import queryString from "query-string";
import { useRef, useEffect } from "react";
import { useSearchState } from "../../api/SearchProvider";

const styles = {
  root: css`
    padding: 0 2.5rem;
    display: flex;
    flex-direction: column;
  `,
  message: css`
    max-width: 860px;
    width: 100%;
  `,
  breadcrumbs: css`
    margin-bottom: 1.5rem;
  `,
};

export default function BotPage() {
  const { search } = useLocation();
  const initialSymptoms = queryString.parse(search).symptoms as string;
  const { data, updateQuery } = useBotSurvey();
  const messages = data.get("messages");
  const refMessagesDiv = useRef<any>(null);
  const { queryParams } = useSearchState();

  // Only show one more message than for which there are answers
  // (unless each one has already been answered => show all)
  const visibleMessages = data
    .get("messages")
    .take(data.get("answers").count() + 1);

  // Check if should scroll into view
  useEffect(() => {
    const div = refMessagesDiv.current;
    if (div) {
      div.scrollIntoView({
        block: "start",
        behavior: "smooth",
        inline: "start",
      });
    }
  }, [messages]);

  // Check if query parameters have changed (e.g. through new autocomplete click)
  useEffect(
    () => updateQuery(queryParams.query.toLowerCase() || initialSymptoms),
    [initialSymptoms, queryParams, updateQuery]
  );

  return (
    <div css={styles.root}>
      <Breadcrumbs items={[{ title: "Botti" }]} css={styles.breadcrumbs} />
      <div className="bot-messages">
        {visibleMessages.map((message, idx) => (
          <div key={idx} className="bot-message" css={styles.message}>
            <BotMessage messages={message.get("questions")} />
            <AnswerOptionsContainer
              messageIndex={idx}
              options={message.get("answerOptions")}
            />
          </div>
        ))}
        <div ref={refMessagesDiv}></div>
        {/* Used to scroll to bottom */}
      </div>
    </div>
  );
}
