import React from "react";
import "../static/css/FeedbackButton.css";
import openImage from "../static/img/evaluation.svg";

export default function FeedbackButton({ url }: any) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <button className="kuura-chat-toggle closed-chat pulse">
        <img className="feedback-toggle" src={openImage} alt="Avaa palaute" />
      </button>
    </a>
  );
}
