/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  Descriptions,
  Divider,
  Empty,
  List,
  Button,
} from "antd";
import paths from "../../paths";
import { useJSTState, useJSTDispatch } from "../../api/JSTProvider";
import {
  JSTQuestionID,
  IJSTQuestion,
  IJSTQuestionResponse,
  ISurveyResults,
  getExternalResources,
  Resource,
  ISurveyTriage,
  ISurveyPlan,
} from "../../api/jst";
import {
  map,
  groupBy,
  uniq,
  uniqBy,
  reduce,
  sortBy,
  pipe,
  intersperse,
  update,
  findLastIndex,
  append,
} from "ramda";
import { useLocalStorage } from "../../utils/localStorage";
import Immutable from "immutable";
import Breadcrumbs from "../../components/Breadcrumbs";
import { ageGroups } from "../SymptomPage/SymptomSelect";
import { copyToClipboard } from "../../utils/copyToClipboard";

const { Text } = Typography;

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  stickyRoot: css`
    padding: 0 2.5rem;
    position: sticky;
    top: 0;
  `,
  topBar: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 860px;
    width: 100%;
  `,
  cards: css`
    display: flex;
    flex-direction: column;
    & > div.ant-card {
      max-width: 860px;
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  `,
  breadcrumbs: css`
    margin-bottom: 1rem;
  `,
  listDescriptions: css`
    td.ant-descriptions-item {
      vertical-align: top;
    }
    ul {
      padding-left: 2rem;
    }
  `,
  emergency: css`
    td.ant-descriptions-item {
      display: flex;
      & > .ant-descriptions-item-label {
        width: 100%;
        max-width: max-content;
      }
    }
  `,
  alarmingCard: css`
    .ant-card-head-title {
      color: #f27373;
    }
  `,
  continuationList: css`
    ul.ant-list-item-action {
      margin-left: 2rem;
    }
  `,
};

// --- helpers ---

const mergeQA = (responses: Record<JSTQuestionID, IJSTQuestionResponse>) => (
  question: IJSTQuestion
) => {
  return {
    selite: question?.selite,
    hakusanat: question.hakusanat,
    ...{ answer: responses[question._id] },
  };
};

const answerToString = (answer?: number) => {
  const lookup = { "-1": "Ei", "0": "En tiedä", "1": "Kyllä" } as Record<
    string,
    string
  >;
  return answer !== undefined ? lookup[`${answer}`] : "Tuntematon";
};

const stringifyAnswers = (mergedQA: Record<string, any>[]) => {
  return mergedQA.reduce((acc: string[], val: Record<string, any>) => {
    const { kesto, lisatieto } = val.answer;
    const baseAnswer = kesto
      ? `${val.answer.kesto} ${val.selite}`
      : (val.selite as string);
    return [...acc, lisatieto ? `${baseAnswer} (${lisatieto})` : baseAnswer];
  }, []);
};

const getArticlesList = (
  allResults: Record<string, any>[],
  resources: Immutable.List<Immutable.Record<Resource>>
) => {
  // Accumulator for getting all articles
  const articlesListAccumulator = (
    acc: Record<string, any>[],
    val: Record<string, any>
  ) => [...acc, ...val.artikkelit];

  // We want to know which domain to link to, so we'll use the article.tunnus "prefix" for that
  const extractPrefix = (tunnus: string) =>
    (tunnus.match(/[a-zA-Z]+/) || [])[0];

  // The sources provided by the API endpoint are given as an array, but being able to index by site id is preferable here
  const sources = resources.reduce(
    (acc, val) => acc.set(val.get("id"), val),
    Immutable.Map<string, Immutable.Record<Resource>>()
  );

  return pipe(
    reduce(articlesListAccumulator, []),
    uniqBy((article) => article.tunnus),
    sortBy((article) => article.jarjestys),
    map((article) => {
      const prefix = extractPrefix(article.tunnus);
      const site = sources.get(prefix);
      return site && article
        ? {
            site: site.get("kuvaus"),
            url: `${site.get("url")}${article.tunnus}`,
            label: article.otsikko,
          }
        : { site: "", label: article.otsikko };
    }),
    groupBy((article) => article.site)
  )(allResults);
};

const getCodes = (allResults: Record<string, any>[]) => {
  // Accumulator for getting all codes
  const codesListAccumulator = (
    acc: Record<string, any>[],
    val: Record<string, any>
  ) => [...acc, ...val.koodit];

  return pipe(
    reduce(codesListAccumulator, []),
    uniqBy((code) => code.koodi),
    groupBy((code) => code.koodityyppi)
  )(allResults);
};

// --- components

function Profile({ dob, age, gender }: any) {
  return (
    <Descriptions column={1}>
      <Descriptions.Item label={dob.title}>{dob.value}</Descriptions.Item>
      <Descriptions.Item label={age.title}>{age.value}</Descriptions.Item>
      <Descriptions.Item label={gender.title}>{gender.value}</Descriptions.Item>
    </Descriptions>
  );
}

function Summary({ summary }: { summary: Record<string, any> }) {
  return (
    <Card
      title="Vastausten yhteenveto"
      actions={[
        <Button onClick={() => copyToClipboard(stringifySummary(summary))}>
          Kopioi yhteenveto
        </Button>,
      ]}
    >
      <Profile dob={summary.dob} age={summary.age} gender={summary.gender} />
      <Descriptions column={1}>
        <Descriptions.Item label={summary.reason.title}>
          {summary.reason.value}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions
        layout="horizontal"
        column={1}
        css={styles.listDescriptions}
      >
        <Descriptions.Item label={summary.symptoms.yes.title}>
          {symptomsBySentiment("positive", summary.symptoms.yes.value)}
        </Descriptions.Item>
        <Descriptions.Item label={summary.symptoms.no.title}>
          {symptomsBySentiment("negative", summary.symptoms.no.value)}
        </Descriptions.Item>
        <Descriptions.Item label={summary.symptoms.unsure.title}>
          {symptomsBySentiment("unsure", summary.symptoms.unsure.value)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

function Codes({ results }: { results: ISurveyResults }) {
  const { todennakoiset, mahdolliset } = results;
  // HACK: uncertain which to show -> show first
  const codes = getCodes(
    [
      ...(todennakoiset as Record<string, any>[]),
      ...(mahdolliset as Record<string, any>[]),
    ].slice(0, 1)
  );
  const count = Object.values(codes).length;

  return (
    <Card title="Koodit">
      {count === 0 ? (
        <Empty description="Ei koodeja" />
      ) : (
        Object.keys(codes).map((codetype) => (
          <Descriptions key={codetype} title={codetype} column={1}>
            {codes[codetype].map((code) => (
              <Descriptions.Item key={code.koodi} label={code.koodi}>
                {code.otsikko}
              </Descriptions.Item>
            ))}
          </Descriptions>
        ))
      )}
    </Card>
  );
}

const formatResults = (results: ISurveyResults) => {
  const { todennakoiset, mahdolliset } = results;
  // HACK: uncertain which to show -> show first
  const allResults = [
    ...(todennakoiset as Record<string, any>[]),
    ...(mahdolliset as Record<string, any>[]),
  ].slice(0, 1);
  const entries = uniq(
    allResults.map(
      (item) => item.lisatieto && item.lisatieto.replace(/\r/g, "<br />")
    )
  );
  return { allResults, entries };
};

function Triage({ results }: { results: ISurveyResults }) {
  const state = useJSTState();
  const dispatch = useJSTDispatch();
  const { allResults, entries } = formatResults(results);
  const [initialized, setInitialized] = useState(false);
  const [articles, setArticles] = useState<null | Record<string, any>>(null);

  useEffect(() => {
    if (!initialized) {
      // Ensure scroll position is reset
      window.scrollTo(0, 0);
      // Handle fetch
      getExternalResources(dispatch);
      setInitialized(true);
    }

    const resources = state.get("resources");
    if (resources && resources.count() > 0 && !articles) {
      setArticles(getArticlesList(allResults, resources));
    }
  }, [allResults, articles, dispatch, initialized, state]);

  return (
    <Card title="Triage">
      {articles &&
        Object.values(entries).length === 0 &&
        Object.values(articles).length === 0 && (
          <Empty description={"Ei lisätietoja"} />
        )}
      {entries.map((item, idx) => (
        <div key={idx} dangerouslySetInnerHTML={{ __html: item }} />
      ))}
      {articles && Object.keys(articles).length > 0 && <Divider />}
      {articles &&
        Object.keys(articles)
          .filter((key) => key !== "")
          .map((key) => (
            <React.Fragment key={key}>
              <Typography>{key}</Typography>
              <ul>
                {articles[key].map((article: Record<string, any>) => (
                  <li key={article.url}>
                    <a href={article.url}>{article.label}</a>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
    </Card>
  );
}

function EmergencyRecommendations({ triage }: { triage: ISurveyTriage[] }) {
  return !triage ? (
    <React.Fragment></React.Fragment>
  ) : (
    <Card title="Kiireellisyyssuositukset">
      <Descriptions column={1} layout="horizontal" css={styles.emergency}>
        {triage.map((item, idx: number) => (
          <Descriptions.Item key={idx} label={item.triage}>
            {item.suositus}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Text type="secondary">
        Lähde: Sosiaali- ja terveysministeriön valtakunnalliset yhtenäiset
        kiireellisen hoidon perusteet.
      </Text>
    </Card>
  );
}

function Alarming({ questions }: { questions: IJSTQuestion[] }) {
  const alarmingQuestions = questions.filter(
    (q) => q.vakava === "K" && q.selite && q.vastaus.vastaus === 1
  );
  if (alarmingQuestions.length === 0) return <React.Fragment></React.Fragment>;

  return (
    <Card title="Hälyttävät oireet" css={styles.alarmingCard}>
      <ul>
        {alarmingQuestions.map((question, idx) => (
          <li>{question.selite}</li>
        ))}
      </ul>
    </Card>
  );
}

function symptomsBySentiment(
  sentiment: "positive" | "negative" | "unsure",
  symptoms: readonly string[]
) {
  const joiner = { positive: "ja", negative: "eikä", unsure: "tai" }[sentiment];
  return pipe(
    intersperse(", "),
    (x: readonly string[]) => {
      const idx = findLastIndex((elt) => elt === ", ")(x);
      return idx > 0 ? update(idx, ` ${joiner} `, x) : x;
    },
    append(".")
  )(symptoms);
}

function stringifySummary(summary: Record<string, any>) {
  const basicsStringified = [
    summary.dob,
    summary.age,
    summary.gender,
    summary.reason,
  ].map((entry) => `${entry.title}: ${entry.value}`);

  const symptomstringified = {
    yes: symptomsBySentiment("positive", summary.symptoms.yes.value),
    no: symptomsBySentiment("negative", summary.symptoms.no.value),
    unsure: symptomsBySentiment("unsure", summary.symptoms.unsure.value),
  };

  return [
    basicsStringified.join("\n"),
    `${summary.symptoms.yes.title}: ${symptomstringified.yes.join("")}`,
    `${summary.symptoms.no.title}: ${symptomstringified.no.join("")}`,
    `${summary.symptoms.unsure.title}: ${symptomstringified.unsure.join("")}`,
  ].join("\n");
}

function Continuation({
  plan,
  summary,
}: {
  plan: ISurveyPlan[];
  summary: Record<string, any>;
}) {
  if (!plan) return <React.Fragment></React.Fragment>;

  return (
    <Card title="Jatkosuunnitelmat">
      <List
        css={styles.continuationList}
        dataSource={plan}
        renderItem={(item: ISurveyPlan, idx: number) => (
          <List.Item
            key={idx}
            actions={[
              <Button onClick={() => copyToClipboard(item.suunnitelma)}>
                Kopioi
              </Button>,
              <Button
                onClick={() => {
                  const fullString = [
                    stringifySummary(summary),
                    "",
                    item.suunnitelma,
                  ].join("\n");

                  copyToClipboard(fullString);
                }}
              >
                Kopioi myös yhteenveto
              </Button>,
            ]}
          >
            {item.suunnitelma}
          </List.Item>
        )}
      />
    </Card>
  );
}

function makeSummary(state: any) {
  const qa = pipe(
    map(mergeQA(state.responses)),
    groupBy((merged: any) => answerToString(merged?.answer?.vastaus))
  )(state.questions);

  return {
    dob: {
      title: "Syntymäaika",
      value: "Ei tietoa",
    },
    age: {
      title: "Ikäryhmä",
      value: state.age
        ? Object.keys(ageGroups).find((key) => ageGroups[key] === state.age)
        : "Ei tietoa",
    },
    gender: {
      title: "Sukupuoli",
      value: state.gender
        ? state.gender === "N"
          ? "Nainen"
          : "Mies"
        : "Ei tietoa",
    },
    reason: {
      title: "Yhteydenoton syy",
      value: state?.symptoms?.join(", "),
    },
    symptoms: {
      yes: {
        title: "Potilas kertoo, että hänellä on",
        value: !qa["Kyllä"] ? ["Ei mitään"] : stringifyAnswers(qa["Kyllä"]),
      },
      no: {
        title: "Potilas kertoo, että hänellä ei ole",
        value: !qa["Ei"] ? ["Ei mitään"] : stringifyAnswers(qa["Ei"]),
      },
      unsure: {
        title: "Potilas ei tiedä onko hänellä",
        value: !qa["En tiedä"]
          ? ["Ei mitään"]
          : stringifyAnswers(qa["En tiedä"]),
      },
    },
  };
}

/*
 * A half-page sideview of a subset of symptom results (next to survey)
 */
function SymptomResultsAside(props: any) {
  const state = useJSTState().toJS();
  const noResults = !state.results;

  return noResults ? (
    <div css={styles.stickyRoot}>
      <Empty description="Vastaa viereiseen kyselyyn niin luomme sinulle koosteen potilaasta" />
    </div>
  ) : (
    <div css={styles.stickyRoot}>
      <div css={styles.cards}>
        {state.questions && <Alarming questions={state.questions} />}
        {state.triage && <EmergencyRecommendations triage={state.triage} />}
      </div>
    </div>
  );
}

/*
 * A full page view of symptom results (for symptom results page)
 */
function SymptomResultsPage() {
  const imstate = useJSTState();
  const dispatch = useJSTDispatch();
  const [stateLocalStorage, setStateLocalStorage] = useLocalStorage(
    "oirekysely"
  );
  const [initializedStorage, setInitializedStorage] = useState(false);
  const {
    results,
    symptoms,
    questions,
    triage,
    plan,
    age,
    gender,
  } = stateLocalStorage; // to preserve from refreshes (esp. during development)
  const summary = useMemo(
    () =>
      stateLocalStorage && Object.keys(stateLocalStorage).length > 0
        ? makeSummary(stateLocalStorage)
        : {},
    [stateLocalStorage]
  );
  const history = useHistory();

  // Update local storage with latest results
  useEffect(() => {
    if (!initializedStorage) {
      // need to reset survey state description
      dispatch({ type: "reset-description" });
      const state = imstate.toJS();
      // don't overwrite with blank results on refresh
      if (state?.results) {
        setStateLocalStorage(state);
      }
      setInitializedStorage(true);
    }
  }, [initializedStorage, setStateLocalStorage, imstate, dispatch]);

  // TODO: show error message prior to redirect
  if (initializedStorage && !results) {
    return <Redirect to={paths.root} />;
  }

  return !initializedStorage ? (
    <div css={styles.root}>Loading...</div>
  ) : (
    <div css={styles.root}>
      <div css={styles.topBar}>
        <Breadcrumbs
          items={[
            {
              title: "Oirekysely",
              href: `${paths.symptom}?symptoms=${symptoms.join(",")}&age=${
                age || ""
              }&gender=${gender || ""}`,
            },
            { title: "Tulokset" },
          ]}
        />
        <Button onClick={() => history.goBack()}>Muuta vastauksia</Button>
      </div>
      <div css={styles.cards}>
        <Alarming questions={questions} />
        <Triage results={results} />
        <Summary summary={summary} />
        <Continuation plan={plan} summary={summary} />
        <EmergencyRecommendations triage={triage} />
        <Codes results={results} />
      </div>
    </div>
  );
}

export default function SymptomResults(props: any) {
  return props.short ? <SymptomResultsAside /> : <SymptomResultsPage />;
}
