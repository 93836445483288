import { useEffect, useState, SetStateAction, Dispatch } from "react";

export function useLocalStorage(
  key: string
): [Record<string, any>, Dispatch<SetStateAction<Record<string, any>>>] {
  const [value, setValue] = useState<Record<string, any>>(
    JSON.parse(localStorage.getItem(key) || "{}")
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
