import React, { useContext, useEffect, useReducer } from "react";
import { UserData, useGetUser } from "./user";

type Action = {
  type: "update-user";
  payload: UserData;
};
export type Dispatch = (action: Action) => void;
export type State = UserData | null;
type ProviderProps = { children: React.ReactNode };

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function userReducer(state: State, action: Action): State {
  if (action.type === "update-user") {
    return action.payload ? action.payload : state;
  } else {
    return state;
  }
}

export function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used inside UserContext");
  }
  return context;
}

export function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used inside UserContext");
  }
  return context;
}

export function UserProvider({ children }: ProviderProps) {
  const { request, data } = useGetUser();
  const [state, dispatch] = useReducer(userReducer, null);

  useEffect(() => {
    request({});
  }, [request]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "update-user", payload: data });
    }
  }, [data]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}
