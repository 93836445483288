import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { isIE, browserVersion } from "react-device-detect";
import { hotjar } from "react-hotjar";
import paths from "./paths";

// Pages
// import SearchPage from "./pages/SearchPage";
import SearchResultsPage from "./pages/SearchResultsPage";
import LoginPage from "./pages/LoginPage";
import SymptomPage from "./pages/SymptomPage";
import SymptomResultsPage from "./pages/SymptomResultsPage";
import BotPage from "./pages/BotPage";
import NotFoundPage from "./pages/NotFoundPage";

// TODO:
// import NotFoundPage from "./pages/NotFoundPage";

// Context Providers
import AuthProvider from "./api/Authorization/AuthProvider";
import { UserProvider } from "./api/UserProvider";
import { SearchProvider } from "./api/SearchProvider";
import { JSTProvider } from "./api/JSTProvider";
import { BotProvider } from "./api/BotProvider";

// Components
import Header from "./components/Header";
import * as PageContent from "./components/PageContent";
import AnonymousOnlyRoute from "./components/AnonymousOnlyRoute";
import AuthorizedOnlyRoute from "./components/AuthorizedOnlyRoute";
import ContextRoute from "./components/ContextRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import FeedbackButton from "./components/FeedbackButton";

// Initialize Hotjar if enabled
if (process.env.REACT_APP_HOTJAR) {
  try {
    const [hjid, hjsv] = process.env.REACT_APP_HOTJAR.split(",").map((i) =>
      parseInt(i)
    );

    hotjar.initialize(hjid, hjsv);
  } catch (err) {
    console.log("Hotjar initialization failed: " + err.message);
  }
}

function App() {
  if (isIE && browserVersion !== "11") {
    return (
      <div className="no-ie-pls">
        <h1>Hei! Käytössäsi on vanhentunut selain.</h1>
        <p>Valitettavasti emme tue Internet Explorer -selainta.</p>
        <p>
          Kehotamme asentamaan esimerkiksi{" "}
          <a href="https://www.mozilla.org/fi/firefox/new/">Firefox</a> tai{" "}
          <a href="https://www.google.com/intl/fi/chrome/">Chrome</a> -selaimen.
        </p>
      </div>
    );
  }

  return (
    <React.StrictMode>
      <AuthProvider>
        <Router>
          <Switch>
            <AnonymousOnlyRoute
              exact
              path={paths.login}
              component={LoginPage}
            />
            <AuthorizedOnlyRoute>
              <SearchProvider>
                <UserProvider>
                  <PageContent.Outer>
                    <Header />
                    <PageContent.Inner>
                      <ErrorBoundary
                        errorHeader="Pahoittelut, jotain meni pieleen."
                        errorBody="Voit yrittää ladata sivun uudestaan tai palata edelliselle sivulle."
                      >
                        <Switch>
                          <Route
                            exact
                            path={paths.search}
                            component={SearchResultsPage}
                          />
                          <Route
                            exact
                            path={paths.results}
                            component={SearchResultsPage}
                          />
                          <ContextRoute
                            exact
                            path={paths.symptom}
                            component={SymptomPage}
                            provider={JSTProvider}
                          />
                          <ContextRoute
                            exact
                            path={paths.symptomResults}
                            component={SymptomResultsPage}
                            provider={JSTProvider}
                          />
                          <ContextRoute
                            exact
                            path={paths.bot}
                            component={BotPage}
                            provider={BotProvider}
                          />

                          {/* If route doesn't match any of the above, then redirect to the 404 page */}
                          <Route path="/404" component={NotFoundPage} />
                          <Redirect from="*" to="/404" />
                        </Switch>
                      </ErrorBoundary>
                    </PageContent.Inner>
                    <FeedbackButton url="https://docs.google.com/forms/d/e/1FAIpQLSdwAONFGB7G2eor_4euxP-m7AHGnVrKZI_9rR1q1M-MADKpAA/viewform?vc=0&c=0&w=1&flr=0" />
                  </PageContent.Outer>
                </UserProvider>
              </SearchProvider>
            </AuthorizedOnlyRoute>
          </Switch>
        </Router>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
